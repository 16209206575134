import React from "react";
import Layout from 'src/components/layout';
import { Link } from "gatsby";


export default function GamePage() {
    return <Layout>
        <h1>Games</h1>

        <ul>
            <li>
                <Link to="./hangman">
                    Hangman
                </Link>
            </li>
        </ul>

    </Layout>;
}
